:root {
  --primary-brand: #ff1e56;
  --secondary-brand: #000;
  --tertiary-brand: #ffac41;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-brand: #d7385e;
    --secondary-brand: #e7d39f;
    --tertiary-brand: #522d5b;
  }
}

* {
  box-sizing: border-box;
}

body {
  color: var(--secondary-brand);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.grid {
  display: grid;
}

.grid-columns-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-rows-2 {
  grid-template-rows: 1fr 1fr;
}

.grid-span-rows-2 {
  grid-row: 1 / span 2;
}

.grid-column-2 {
  grid-column: 2;
}

.grid-row-2 {
  grid-row: 2;
}

.flex {
  display: flex;
}

.flex-vertical {
  flex-direction: column;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.height-screen {
  height: 100vh;
}

.width-screen {
  width: 100vw;
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100%;
}

.padding {
  padding: 20px;
}

.margin-left {
  margin-left: 10px;
}

.list-style-none {
  list-style: none;
}

.bg-primary-brand {
  background-color: var(--primary-brand);
}

.color-primary-brand {
  color: var(--primary-brand);
}

.color-inherit {
  color: inherit;
}

.bg-tertiary-brand {
  background-color: var(--tertiary-brand);
}

.color-tertiary-brand {
  color: var(--tertiary-brand);
}

.fill-secondary-brand {
  fill: var(--secondary-brand);
}

.text-decoration-none {
  text-decoration: none;
}

.attention-seeker {
  font-size: 4rem;
  transform: translateX(11%);
}

.whats-my-name {
  font-size: 1.25rem;
  transform: translate(1px, 24px);
}
